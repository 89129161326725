
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer} from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import Form from "./components/Form"
import Landing from './pages/Landing';
function App() {
   
  return (
   
    <Router>
       <ToastContainer/>
      <div>
        <Routes>
          {/* Define the route for the Landing page */}
          <Route path="/" element={<Landing />} />
          <Route path="/register" element={<Form />} />
          
        </Routes>
      </div>
    </Router>
  );
}

export default App;
