import React, { useState, useRef } from "react";
import { motion } from "framer-motion";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from '../assets/logo-removebg-preview (1).png'
const CricketTournamentForm = () => {
  const [formData, setFormData] = useState({
    nickName: "",
    fatherName: "",
    cnic: "",
    cellNumber: "",
    address: "",
    currentTeam: "",
    playerRole: "",
    tshirtSize: "",
    tshirtNumber: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  // Create refs for file inputs
  const passportImageRef = useRef(null);
  const cnicFrontRef = useRef(null);
  const cnicBackRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateForm = () => {
    for (const key in formData) {
      if (!formData[key]) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      toast.error("Please fill in all fields, including images and selections.");
      return;
    }

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }
    formDataToSend.append("passportImage", passportImageRef.current.files[0]);
    formDataToSend.append("cnicFront", cnicFrontRef.current.files[0]);
    formDataToSend.append("cnicBack", cnicBackRef.current.files[0]);

    setIsLoading(true);

    try {
      const response = await fetch("https://synapra.com.skahmerali.com/api/register", {
        method: "POST",
        body: formDataToSend,
      });
      const data = await response.json();
      toast.success(data.message);

      // Clear form data after successful submission
      setFormData({
        nickName: "",
        fatherName: "",
        cnic: "",
        cellNumber: "",
        address: "",
        currentTeam: "",
        playerRole: "",
        tshirtSize: "",
        tshirtNumber: "",
      });

      // Reset the file input fields using refs
      passportImageRef.current.value = null;
      cnicFrontRef.current.value = null;
      cnicBackRef.current.value = null;

    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="min-h-screen bg-cover bg-center"
      style={{
        backgroundImage:
          "url('https://i0.wp.com/images.augustman.com/wp-content/uploads/sites/6/2023/04/16072649/Untitled-design-2023-04-16T071319.214.jpg?strip=all')",
      }}
    >
      <div className="flex items-center justify-center h-full px-4 py-6">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="bg-white bg-opacity-5 backdrop-blur-lg p-6 rounded-xl shadow-2xl max-w-3xl w-full"
        >
          <div
            className="bg-green-800 -mt-36 text-white text-center py-40 "
            style={{
              clipPath: "polygon(0 33%, 54% 20%, 99% 33%, 100% 66%, 47% 81%, 0 68%, 0 53%)",
            }}
          >
            <img
              src={Logo} // Update with your logo path
              alt="Logo"
              className="mx-auto mb-4"
              style={{ width: "200px", height: "auto" }}
            />
            <h2 className="text-2xl md:text-3xl lg:text-3xl font-bold tracking-widest">
              TOURNAMENT 2024 (OPL)
            </h2>
            <p className="text-base mb-4 md:text-lg">Orangi Premier League</p>
          </div>

          <form className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6" onSubmit={handleSubmit}>
            {/* Form Fields */}
            {[ 
              { label: "Name", name: "nickName", type: "text", placeholder: "Enter your name" },
              { label: "Father's Name", name: "fatherName", type: "text", placeholder: "Enter father's name" },
              { label: "CNIC", name: "cnic", type: "number", placeholder: "Enter CNIC number" },
              { label: "Cell Number", name: "cellNumber", type: "number", placeholder: "Enter cell number" },
              { label: "Complete Address", name: "address", type: "text", placeholder: "Enter your complete address" },
              { label: "Current Team Name", name: "currentTeam", type: "text", placeholder: "Enter current team name" },
            ].map((field) => (
              <div key={field.name}>
                <label
                  className="block text-lg text-white font-semibold mb-2"
                  htmlFor={field.name}
                >
                  {field.label}
                </label>
                <input
                  type={field.type}
                  id={field.name}
                  name={field.name}
                  placeholder={field.placeholder}
                  value={formData[field.name]} // Control the input
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 bg-white bg-opacity-70 placeholder-gray-500 text-gray-700"
                />
              </div>
            ))}

            {/* Player Role */}
            <div>
              <label className="block text-lg text-white font-semibold mb-2" htmlFor="playerRole">
                Player Role
              </label>
              <select
                id="playerRole"
                name="playerRole"
                value={formData.playerRole} // Control the select
                onChange={handleChange}
                required
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 bg-white bg-opacity-70 text-gray-700"
              >
                <option value="">Select role</option>
                <option value="Batsman">Batsman</option>
                <option value="Bowler">Bowler</option>
                <option value="All-Rounder">All-Rounder</option>
              </select>
            </div>

            {/* T-Shirt Size */}
            <div>
              <label className="block text-lg text-white font-semibold mb-2" htmlFor="tshirtSize">
                T-Shirt Size
              </label>
              <select
                id="tshirtSize"
                name="tshirtSize"
                value={formData.tshirtSize} // Control the select
                onChange={handleChange}
                required
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 bg-white bg-opacity-70 text-gray-700"
              >
                <option value="">Select size</option>
                <option value="S">Small</option>
                <option value="M">Medium</option>
                <option value="L">Large</option>
                <option value="XL">Extra Large</option>
                <option value="XXL">Double Extra Large</option>
              </select>
            </div>

            {/* T-Shirt Number */}
            <div>
              <label className="block text-lg text-white font-semibold mb-2" htmlFor="tshirtNumber">
                T-Shirt Number
              </label>
              <input
                type="number"
                id="tshirtNumber"
                name="tshirtNumber"
                placeholder="Enter T-shirt number"
                value={formData.tshirtNumber} // Control the input
                onChange={handleChange}
                required
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 bg-white bg-opacity-70 placeholder-gray-500 text-gray-700"
              />
            </div>

            {/* Image Uploads */}
            {[
              { label: "Passport Size Image", name: "passportImage", ref: passportImageRef },
              { label: "CNIC Front Image", name: "cnicFront", ref: cnicFrontRef },
              { label: "CNIC Back Image", name: "cnicBack", ref: cnicBackRef },
            ].map((field) => (
              <div key={field.name}>
                <label className="block text-lg text-white font-semibold mb-2" htmlFor={field.name}>
                  {field.label}
                </label>
                <input
                  type="file"
                  id={field.name}
                  name={field.name}
                  ref={field.ref} // Use ref to manage the input
                  required
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 bg-white bg-opacity-70 text-gray-700"
                />
              </div>
            ))}

            {/* Submit Button */}
            <div className="col-span-1">
              <button
                type="submit"
                disabled={isLoading}
                className={`w-full py-3 mt-6 text-white ${isLoading ? "bg-gray-400" : "bg-green-600"} rounded-lg shadow-lg hover:bg-green-700 transition duration-300 ease-in-out`}
              >
                {isLoading ? "Submitting..." : "Submit Registration"}
              </button>
            </div>
          </form>
        </motion.div>
      </div>
    </div>
  );
};

export default CricketTournamentForm;
