import React from 'react';
// import { Play } from 'lucide-react';

export default function Hero() {
  return (
    <div className=" bg-gray-900 text-white">
      <header className="container mx-auto p-4">
        <nav className="flex justify-center space-x-6">
          <a href="/" className="hover:text-green-400">Home</a>
          <a href="/" className="hover:text-green-400">Courses</a>
          <a href="/" className="hover:text-green-400">Contact Us</a>
          <a href="/" className="hover:text-green-400">About Us</a>
        </nav>
      </header>

      <main className="container mx-auto px-4 py-8 md:py-16">
        <div className="grid md:grid-cols-2 gap-8 items-center">
          <div className="space-y-6 flex flex-col items-center md:items-start">
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold leading-tight text-center md:text-left">
              Enroll Today and Transform Your{' '}
              <span className="text-green-400">Cricket Game</span>
            </h1>
            <h2 className="text-xl md:text-2xl italic text-center md:text-left">
              Money-Back Guarantee for Course Satisfaction
            </h2>
            <p className="text-gray-300 text-center md:text-left">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, arcu auctor euismod tristique, libero quam dignissim urna, nec sagittis metus velit sit amet urna. Nunc non feugiat velit.
            </p>
            <button className=" bg-green-400 text-gray-900 px-12 py-3 rounded-sm font-semibold hover:bg-green-300 transition duration-300 mx-auto">
              Enroll Now
            </button>
          </div>

          {/* Image Carousel Section */}
          <div className="relative flex items-center justify-center overflow-hidden">
            {/* Small Image (Behind, on the Right) */}
            <div className="absolute -right-8  top-10 w-72 h-64 sm:w-72 sm:h-64 md:w-72 md:h-72 lg:w-72 lg:h-64 xl:h-96 xl:w-full rounded-lg overflow-hidden border-4 border-green-400 z-0">
              <img
                src="https://resources.cricket-tas.pulselive.com/photo-resources/2024/10/08/d80746ba-1aae-4a8a-a327-8e5ec1bb8cce/Nivethan-Radhakrishnan-Kingborough.png?width=370&height=209"
                alt="Cricket equipment"
                className="w-full h-full object-cover"
              />
            </div>

            {/* Main Image (In Front) */}
            <div className="relative w-[80%] sm:w-[85%] md:w-[90%] lg:w-[95%] rounded-lg overflow-hidden z-10">
              <img
                src="https://img.freepik.com/free-photo/cricket-equipments-green-grass_53876-63200.jpg"
                alt="Cricket equipment"
                className=" w-96 h-96 lg:w-80 lg:h-full xl:w-96 xl:h-full object-cover rounded-lg"
              />
              <div className="absolute inset-0 flex items-center justify-center">
                {/* Play button or overlay can go here if needed */}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
