import React from 'react';

const CourseCard = ({ imageSrc }) => (
  <div className="relative overflow-hidden rounded-sm shadow-lg">
    <img 
      src={imageSrc} 
      alt="Course" 
      className="w-full h-48 sm:h-56 md:h-64 lg:h-72 object-cover" 
    />
    <div className="px-11  absolute top-0 right-0 bg-green-600 bg-opacity-70 text-white text-sm rounded-bl-lg">
      10 Cricurus
    </div>
    <div className="absolute bottom-0 inset-x-0 bg-green-500 bg-opacity-80 text-center py-4">
      <button className="text-white text-lg font-bold w-full h-full">Enroll Now</button>
    </div>
  </div>
);

export default function OnlineCourse() {
  const courses = [
    { image: "https://mams.co.in/wp-content/uploads/2022/11/cricket-academy.jpg" },
    { image: "https://img1.hscicdn.com/image/upload/f_auto,t_ds_square_w_640,q_50/lsci/db/PICTURES/CMS/334600/334617.10.jpg" },
    { image: "https://media.getmyuni.com/assets/images/articles/2tir120026e3kcc07.jpg" }
  ];

  return (
    <div className="bg-gray-900 text-white py-20 p-8">
      <div className="max-w-6xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 ">
          {courses.map((course, index) => (
            <CourseCard key={index} imageSrc={course.image} />
          ))}
        </div>
      </div>
    </div>
  );
}
