import React from 'react';

export default function WhyChoose() {
  return (
    <div className="bg-gray-900 text-white p-8">
      <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-between gap-8">
        <div className="w-full md:w-1/2 space-y-6">
          <h1 className="text-4xl md:text-5xl font-bold leading-tight">
            Why Choose Our <span className="text-green-400">Cricket Courses</span> Online?
          </h1>
          <ul className="space-y-2 text-lg">
            <li>• Expert Instruction</li>
            <li>• Tailored Learning Paths</li>
            <li>• Flexibility and Convenience</li>
            <li>• Community Engagement</li>
            <li>• Comprehensive Fitness Guidance</li>
            <li>• Progress Tracking</li>
            <li>• Exclusive Offers and Guarantees</li>
          </ul>
          <div className="flex justify-center">
            <button className="bg-green-400 text-gray-900 px-8 py-3 rounded-sm font-semibold text-lg hover:bg-green-300 transition duration-300">
              Enroll Now
            </button>
          </div>
        </div>
        <div className="w-full md:w-1/2 relative">
          <div className="absolute inset-0 bg-green-400 rounded-3xl opacity-20 blur-xl"></div>
          <div className="relative">
            <img 
              src="https://static01.nyt.com/images/2023/10/03/multimedia/03xp-cricket1-whzj/03xp-cricket1-whzj-superJumbo.jpg" 
              alt="Cricket field" 
              className="w-full h-auto rounded-xl border-4 border-green-400"
            />
            <img 
              src="https://cdn.britannica.com/09/182109-050-2C066432/Quinton-De-Kock-New-Zealand-Ross-Taylor-March-24-2015.jpg" 
              alt="Cricket player" 
              className="absolute -bottom-6 -left-5 lg:-bottom-14 lg:-left-16 w-1/2 h-auto rounded-xl border-4 border-green-400" // Smaller size for the small image
            />
          </div>
        </div>
      </div>
    </div>
  );
}
