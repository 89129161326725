import React, { useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // Importing arrow icons

const reviews = [
    {
        id: 1,
        name: 'Jack Carter',
        image: 'https://pics.craiyon.com/2024-09-17/xst6cIkFQgCRbqI-sT_d0w.webp',
        title: 'No ordinary in my game',
        heading: 'An Insightful Experience',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
        id: 2,
        name: 'Sunil Shankar',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbEvgKkgzrP4Lq3IZlWBhkLKM_tcWl5-PZRPnt31ngvUyRmsR-4JirfOlqMbyqHrf01iM&usqp=CAU',
        title: 'Excellent Platform',
        heading: 'Highly Recommend!',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam commodo, arcu eget suscipit tincidunt, libero ipsum dignissim urna.',
    },
    {
        id: 3,
        name: 'John White',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZH7YaanBG_DjL8Fiip9LbboWspDRSEWZ_3HSC-_vUC2CaM4JHmr3DAIjbVBOBjvKUy1Y&usqp=CAU',
        title: 'Mental Strength is Key',
        heading: 'A Game Changer',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent euismod, nunc vel tincidunt lacinia, nunc nisl aliquam mauris.',
    },
];

export default function Review() {
    const [currentReview, setCurrentReview] = useState(1);

    const getReviewIndex = (offset) => {
        return (currentReview + offset + reviews.length) % reviews.length;
    };

    const handleClick = (offset) => {
        setCurrentReview(getReviewIndex(offset));
    };

    return (
        <div className="bg-gray-900 text-white py-7 relative overflow-hidden">
            <div className="container mx-auto px-4 relative z-10">
                <h2 className="text-4xl font-bold text-center">Our Players and Parents love us</h2>

                {/* Circular Carousel */}
                <div className="flex justify-center items-center relative">
                    <div className="relative w-64 h-64">
                        {/* Main Circle */}
                        <div className="absolute inset-0 flex justify-center items-center">
                            <div className="w-32 h-32 rounded-full flex justify-center items-center z-10">
                                <img
                                    src={reviews[currentReview].image}
                                    alt="Team Member"
                                    className="rounded-full w-28 h-28 object-cover"
                                />
                            </div>
                        </div>

                        {/* Left Circle (partially visible) */}
                        <div className="absolute left-[0px] top-1/2 transform -translate-y-1/2">
                            <div className="w-24 h-24 rounded-full flex justify-center items-center z-0">
                                <img
                                    src={reviews[getReviewIndex(-1)].image}
                                    alt="Team Member"
                                    className="rounded-full w-20 h-20 object-cover"
                                />
                            </div>
                        </div>

                        {/* Right Circle (partially visible) */}
                        <div className="absolute right-[0px] top-1/2 transform -translate-y-1/2">
                            <div className="w-24 h-24 rounded-full flex justify-center items-center z-0">
                                <img
                                    src={reviews[getReviewIndex(1)].image}
                                    alt="Team Member"
                                    className="rounded-full w-20 h-20 object-cover"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="relative h-[400px] md:h-[300px]">
                    {[-1, 0, 1].map((offset) => {
                        const review = reviews[getReviewIndex(offset)];
                        return (
                            <div
                                key={review.id}
                                onClick={() => offset !== 0 && handleClick(offset)}
                                className={`absolute top-0 left-1/2 w-full max-w-sm md:max-w-md transition-all duration-300 ease-in-out cursor-pointer ${
                                    offset === 0
                                        ? 'z-20 -translate-x-1/2 scale-110 opacity-100'
                                        : offset === -1
                                        ? 'z-10 -translate-x-[100%] scale-90 opacity-70 hover:opacity-90'
                                        : 'z-10 translate-x-[10%] scale-90 opacity-70 hover:opacity-90'
                                }`}
                            >
                                <div className="bg-gray-800 rounded-lg p-6 shadow-lg border-2 border-green-500"> {/* Added green border */}
                                    <div className="flex items-center mb-4">
                                        <img src={review.image} alt={review.name} className="w-16 h-16 rounded-full mr-4" />
                                        <div>
                                            <h3 className="text-xl font-semibold">{review.name}</h3>
                                            <p className="text-green-500">{review.title}</p>
                                        </div>
                                    </div>
                                    {/* Use the heading from the review object */}
                                    <h4 className="text-lg font-bold text-center text-white mb-2">{review.heading}</h4>
                                    <p className="text-gray-300">{review.content}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>

                {/* Arrow Buttons */}
                <div className="absolute top-1/2 left-0 transform -translate-y-1/2">
                    <button onClick={() => handleClick(-1)} className="bg-gray-800 text-white p-2 rounded-full hover:bg-gray-700">
                        <FaChevronLeft />
                    </button>
                </div>
                <div className="absolute top-1/2 right-0 transform -translate-y-1/2">
                    <button onClick={() => handleClick(1)} className="bg-gray-800 text-white p-2 rounded-full hover:bg-gray-700">
                        <FaChevronRight />
                    </button>
                </div>
            </div>
        </div>
    );
}
