import React from 'react'

const CourseCard = ({ title, imageSrc }) => (
  <div className="relative overflow-hidden rounded-sm shadow-lg">
    <img src={imageSrc} alt={title} className="w-full h-48 sm:h-56 md:h-64 lg:h-72 object-cover" />
    <div className="absolute bottom-0 inset-x-0 bg-green-500 bg-opacity-80 text-center py-4">
      <h3 className="text-white text-lg font-bold">{title}</h3>
    </div>
  </div>
)

export default function OnlineCourse() {
  const courses = [
    { title: "Fundamentals Courses", image: "https://media.istockphoto.com/id/177427917/photo/close-up-of-red-cricket-ball-and-bat-sitting-on-grass.jpg?s=612x612&w=0&k=20&c=DcorerbBUeDNTfld3OclgHxCty4jih2yDCzipffX6zw=" },
    { title: "Bowling Courses", image: "https://t4.ftcdn.net/jpg/06/51/04/19/360_F_651041932_tM5IdjfzKlIy7bPY4IIBHu5OR1YfnA4B.jpg" },
    { title: "Batting Courses", image: "https://cdn.britannica.com/63/211663-050-A674D74C/Jonny-Bairstow-batting-semifinal-match-England-Australia-2019.jpg" }
  ]

  return (
    <div className="bg-gray-900 text-white p-8">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl font-bold mb-28 text-center">Online Training Courses</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {courses.map((course, index) => (
            <CourseCard key={index} title={course.title} imageSrc={course.image} />
          ))}
        </div>
      </div>
    </div>
  )
}
