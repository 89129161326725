import React from 'react'
import Nav from '../components/Nav'
import Hero from '../components/Hero'
import Whychoose from '../components/Whychoose'
import OnlineCourse from '../components/OnlineCourse'
import Footer from '../components/Footer'
import Review from '../components/Review'
import Enroll from '../components/Enroll'
const Landing = () => {
  return (
    <div>
        <Nav/>
        <Hero/>
        <OnlineCourse/>
        <Whychoose/>
        <Enroll/>
        <Review/>
        <Footer/>
    </div>
  )
}

export default Landing