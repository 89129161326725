import { Instagram, Facebook, Twitter } from 'lucide-react';
import { Link } from 'react-router-dom';
import Logo from '../assets/ball.png';

export default function Footer() {
  return (
    <footer className="bg-black text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-center items-center text-center">
          {/* Logo and Tagline */}
          <div className="w-full md:w-1/3 mb-6 md:mb-0 flex flex-col items-center">
            <div className="flex items-center mb-4 justify-center">
              <img
                src={Logo}
                alt="Lady Bug Logo"
                className="w-16 h-16 mr-3" 
              />
              <h2 className="text-4xl font-bold flex">
                <span className="text-green-500">Lady</span>{' '}
                <span className="text-white ml-1">Bug</span>
              </h2>
            </div>
            <p className="text-xl">Game up with the Best!</p>
          </div>

          {/* Useful Links */}
          <div className="w-full md:w-1/3 mb-6 md:mb-0 text-center">
            <h3 className="text-2xl font-semibold text-green-500 mb-4">Useful Links</h3>
            <ul className="space-y-2 text-lg">
              <li><Link to="/" className="hover:text-green-500 transition-colors">About Us</Link></li>
              <li><Link to="/" className="hover:text-green-500 transition-colors">Contact Us</Link></li>
              <li><Link to="/" className="hover:text-green-500 transition-colors">Privacy Policy</Link></li>
              <li><Link to="/" className="hover:text-green-500 transition-colors">Terms & Conditions</Link></li>
            </ul>
          </div>

          {/* Support */}
          <div className="w-full md:w-1/3 flex flex-col items-center">
            <h3 className="text-2xl font-semibold text-green-500 mb-4">Support</h3>
            <p className="mb-4 text-lg">hanzaladishad22@gmail.com</p>
            <div className="flex space-x-4 mb-4 justify-center">
              <Link to="/" className="text-white  transition-colors">
                <div className="w-10 h-10 bg-green-500 rounded-full flex items-center justify-center">
                  <Instagram size={24} />
                </div>
              </Link>
              <Link to="/" className="text-white  transition-colors">
                <div className="w-10 h-10 bg-green-500 rounded-full flex items-center justify-center">
                  <Facebook size={24} />
                </div>
              </Link>
              <Link to="/" className="text-white  transition-colors">
                <div className="w-10 h-10 bg-green-500 rounded-full flex items-center justify-center">
                  <Twitter size={24} />
                </div>
              </Link>
            </div>
            <p className="text-sm">&copy; 2024 Orangi Premier League</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
