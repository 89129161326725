import React from 'react';
import { IoIosSearch } from 'react-icons/io';
import Logo from '../assets/ball.png'
import { Link } from 'react-router-dom';
const NavBar = () => {
  return (
    <nav className="bg-black text-white flex items-center justify-between py-0 px-6 border-b-2 border-green-600">
      {/* Left side: Search bar hidden on small screens */}
      <div className="relative hidden md:flex items-center w-full md:w-auto">
        <input 
          type="text" 
          placeholder="Find Courses" 
          className="p-2 pl-10 rounded-full bg-gray-800 placeholder-gray-400 text-white focus:outline-none w-full md:w-64" 
        />
        {/* Green background search icon inside input */}
        <div className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-green-500 p-2 rounded-r-full">
          <IoIosSearch size={20} className="text-white" />
        </div>
      </div>

      {/* Middle: Logo */}
      <div className="flex items-center space-x-2">
        <img 
          src={Logo} 
          alt="Logo" 
          className="h-20 w-20"
        />
        <span className="text-green-500 text-5xl font-bold">OPL</span>
      </div>

      {/* Right side: Sign Up & Login */}
      <div className="flex space-x-6 items-center">
        {/* Sign Up button hidden on small screens */}
        <button className="hidden md:block text-white hover:text-gray-300">Sign Up</button>
        {/* Login button visible on all screens */}
        <Link to={'/register'} className="bg-green-500 hover:bg-green-600 text-white py-2 px-6 rounded-lg">Register</Link>
      </div>
    </nav>
  );
};

export default NavBar;
